@import "~@blueprintjs/core/lib/scss/variables";

.color-square {
  cursor: pointer;
  height: 25px;
  position: relative;
  width: 25px;

  &.active::after {
    border: 2px dashed $black;
    content: "";
    height: 31px;
    left: -3px;
    position: absolute;
    top: -3px;
    width: 31px;
  }
}

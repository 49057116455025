@import "~@blueprintjs/core/lib/scss/variables";

.app {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 100vh;
  padding: 50px;
  position: relative;
  width: 100%;
}

.app-background {
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.app-content {
  position: relative;
}

// https://gist.github.com/MikhaiTran/7aa49ff3a18549587108b571e20c054b
// n is number of stars required
@function multiple-box-shadow($n) {
  $value: "#{random(2000)}px #{random(2000)}px #FFF";
  @for $i from 2 through $n {
    $value: "#{$value}, #{random(2000)}px #{random(2000)}px #FFF";
  }

  @return unquote($value);
}

$shadows-small: multiple-box-shadow(700);
$shadows-medium: multiple-box-shadow(200);
$shadows-big: multiple-box-shadow(100);

.stars {
  animation: star-animation 50s linear infinite;
  background: transparent;
  box-shadow: $shadows-small;
  height: 1px;
  width: 1px;

  &::after {
    background: transparent;
    box-shadow: $shadows-small;
    content: " ";
    height: 1px;
    position: absolute;
    top: 2000px;
    width: 1px;
  }
}

.stars2 {
  animation: star-animation 100s linear infinite;
  background: transparent;
  box-shadow: $shadows-medium;
  height: 2px;
  width: 2px;

  &::after {
    background: transparent;
    box-shadow: $shadows-medium;
    content: " ";
    height: 2px;
    position: absolute;
    top: 2000px;
    width: 2px;
  }
}

.stars3 {
  animation: star-animation 150s linear infinite;
  background: transparent;
  box-shadow: $shadows-big;
  height: 3px;
  width: 3px;

  &::after {
    background: transparent;
    box-shadow: $shadows-big;
    content: " ";
    height: 3px;
    position: absolute;
    top: 2000px;
    width: 3px;
  }
}

@keyframes star-animation {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-2000px);
  }
}

@import "~@blueprintjs/core/lib/scss/variables";

.error-boundary {
  align-items: center;
  background-color: $light-gray5;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding: 50px;
  width: 100vw;
}

.error {
  background-color: $white;
  max-height: 500px;
  max-width: 80%;
  overflow: auto;
}

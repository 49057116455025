@import "~@blueprintjs/core/lib/scss/variables";

.header {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.logo {
  font-size: 48px;
  height: 65px;
  margin-right: 10px;
  width: 65px;
}

.text.text {
  color: $light-gray1;
}

@import "~@blueprintjs/core/lib/scss/variables";

.board-container {
  background: $light-gray1;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.canvas-container {
  border: 1px solid $pt-divider-black;
  box-sizing: content-box;
  height: 1000px;
  overflow: hidden;
  position: relative;
  width: 1000px;
}

.loading-bar-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;

  .loading-bar {
    width: 50%;
  }
}

.canvas {
  image-rendering: pixelated;
}

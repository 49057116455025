@import "~@blueprintjs/core/lib/css/blueprint.css";

body {
  margin: 0;
}

/* stylelint-disable-next-line selector-max-universal */
* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "source-code-pro", "Menlo", "Monaco", "Consolas", "Courier New",
    monospace;
}
